import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import LoginFormik from '../views/auth/LoginFormik';

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const AdminStarter = Loadable(lazy(() => import('../views/admin/AdminStarter')));
const AdminOrders = Loadable(lazy(() => import("../views/admin/AdminOrders")));
const UpdateOrder = Loadable(lazy(() => import("../views/admin/UpdateOrder")));
const ClientResults = Loadable(lazy(() => import("../views/admin/ClientResults")));
const DriverResults = Loadable(lazy(() => import("../views/admin/DriverResults")));

const ThemeRoutes = [
  {
    path: '/admin',
    exact: true,
    element: <FullLayout />,
    children: [
      { path: '/admin', element: <Navigate to="/admin/starter" /> },
      { path: '/admin/starter', exact: true, element: <AdminStarter /> },
      { path: '/admin/orders', exact: true, element: <AdminOrders /> },
      { path: "/admin/orders/:id/update", exact: true, element: <UpdateOrder /> },
      { path: '/admin/client-results', exact: true, element: <ClientResults /> },
      { path: '/admin/driver-results', exact: true, element: <DriverResults /> },
      { path: '/admin/*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/admin/auth',
    exact: true,
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
    ],
  },
  { path: '404', element: <Error /> },
];

export default ThemeRoutes;
