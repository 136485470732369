import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { requestStarted, requestCompleted} from './loader/loaderActions';
import CustomizerReducer from './customizer/CustomizerSlice';
import UserReducer from './user/userReducers';
import AlertReducer from './alert/alertReducers';
import LoaderReducer from './loader/loaderReducer';
import ClientReducer from './client/clientReducers';
import DriverReducer from './driver/driverReducers';
import OrderReducer from './order/orderReducer';

const loadingMiddleware = ({ dispatch }) => next => action => {
  if (action.type.endsWith('_REQUEST')) {
    dispatch(requestStarted());
  } else if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAILURE')) {
    dispatch(requestCompleted());
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    user: UserReducer,
    alert: AlertReducer,
    loader: LoaderReducer,
    client: ClientReducer,
    driver: DriverReducer,
    order: OrderReducer,
  },
  middleware: [...getDefaultMiddleware(), loadingMiddleware],
});


export default store;
